<script setup lang="ts">
import { computed, ref, toRef, watch } from 'vue'
import ModalContainer from '@/components/modal/modal-container.vue'
import ModalTitle from '@/components/modal/modal-title.vue'
import ModalContent from '@/components/modal/modal-content.vue'
import GridContainer from '@/components/grid-container.vue'
import GridItem from '@/components/grid-item.vue'
import InputText from '@/components/InputText.vue'
import InputLabel from '@/components/input-label.vue'
import InputSelect from '@/components/InputSelect.vue'
import InputDate from '@/components/InputDate.vue'
import InputNumber from '@/components/InputNumber.vue'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import { meterBillingRegisters, MeterStatus, MeterType, meterTypes } from '@/features/assets/constants'
import ModalFooter from '@/components/modal/modal-footer.vue'
import ButtonPrimary from '@/components/ButtonPrimary.vue'
import { faFloppyDisk, faTimes } from '@fortawesome/pro-regular-svg-icons'
import ButtonSecondary from '@/components/ButtonSecondary.vue'
import InputTime from '@/components/input-time.vue'
import { useLinkedMeters, useLinkMeter, useUnlinkedMeters } from '@/composables/assets/meters'
import { DateFormats } from '@/helpers/formatting'
import { format } from 'date-fns/format'
import HeroSplashError from '@/components/hero-splash-error.vue'
import { ActualMeterOperationalFormSchema, ActualMeterDecommissionedFormSchema, VirtualMeterFormSchema } from '@/features/assets/schemas'
import InputHidden from '@/components/input-hidden.vue'
import useNotifications from '@/composables/notifications'

const emit = defineEmits<{ close: [] }>()
const props = defineProps<{ assetCode: number, meterId?: number }>()
const { addSuccess, addError } = useNotifications()

const selectedMeterType = ref<MeterType>()
const selectedMeterStatus = ref<MeterStatus|null>(null)

const { data: linkedMeters } = useLinkedMeters(
  toRef(props, 'assetCode')
)

const isUpdating = computed(() => !!props.meterId)

const meterDetails = computed(
  () => isUpdating.value && linkedMeters.value !== undefined
    ? linkedMeters.value!.find(x => x.id === props.meterId)
    : undefined
)

const { values: currentValues, handleSubmit } = useForm({
  validationSchema: computed(
    () => {
      if (selectedMeterStatus.value === MeterStatus.Decommissioned || meterDetails.value?.meterStatus === MeterStatus.Decommissioned) {
        return toTypedSchema(ActualMeterDecommissionedFormSchema)
      }

      if (selectedMeterType.value === MeterType.Virtual || meterDetails.value?.meterType === MeterType.Virtual) {
        return toTypedSchema(VirtualMeterFormSchema)
      }

      return toTypedSchema(ActualMeterOperationalFormSchema)
    }
  )
})

const { data: unlinkedMeters, isFetching: isFetchingUnlinkedMeters } = useUnlinkedMeters(
  toRef(props, 'assetCode')
)

watch(
  meterDetails,
  (newValue) => {
    if (newValue === undefined) {
      return
    }

    selectedMeterType.value = newValue.meterType
    selectedMeterStatus.value = newValue.meterStatus
  },
  { immediate: true }
)

const selectedUnlinkedMeter = computed(
  () => unlinkedMeters.value?.find(x => x.deviceId === currentValues.deviceId)
)

const { mutateAsync: linkMeterAsync, isPending: isLinkingMeter } = useLinkMeter(toRef(props, 'assetCode'))

const doSubmit = handleSubmit(
  async (values) => {
    try {
      await linkMeterAsync(values)

      addSuccess({
        title: 'Meter linked successfully.',
        allowHtml: true,
        message: `Meter <b>${values.name}</b> was ${values.id === undefined ? 'created' : 'updated'} successfully.`
      })

      emit('close')
    } catch {
      addError({
        title: 'Meter not linked.',
        allowHtml: true,
        message: `This meter could not be ${values.id === undefined ? 'created' : 'updated'}. Please try again.`
      })
    }
  }
)

const availableVcomIds = computed(
  () => {
    const unlinkedIds = (unlinkedMeters.value ?? []).map(x => x.vcomDeviceId).filter(x => typeof x === 'string')
    const linkedIds = (linkedMeters.value ?? []).map(x => x.vcomDeviceId).filter(x => typeof x === 'string')

    const availableIds = unlinkedIds.filter(x => linkedIds.indexOf(x) === -1)

    if (meterDetails.value?.vcomDeviceId) {
      availableIds.push(meterDetails.value.vcomDeviceId)
    }

    return availableIds.map(x => ({ text: x, value: x }))
  }
)

const availableDeviceIds = computed(
  () => (unlinkedMeters.value ?? [])
    .map(x => x.deviceId)
    .concat(
      isUpdating.value && meterDetails.value?.meterStatus === MeterStatus.Decommissioned
        ? (linkedMeters.value ?? []).map(x => x.deviceId)
        : []
    )
    .concat(meterDetails.value ? [meterDetails.value!.deviceId] : [])
    .reduce(
      (accumulator, current) => accumulator.includes(current) ? accumulator : [...accumulator, current],
      [] as string[]
    )
    .sort()
    .map(x => ({ deviceId: x }))
)

const availableMeterTypes = computed(
  () => meterDetails.value?.meterType === MeterType.Virtual
    ? meterTypes
    : meterTypes.filter(x => x.value !== MeterType.Virtual)
)

const commissioningDateValue = computed(
  () => {
    if (meterDetails.value) {
      return meterDetails.value.commissioning
    }

    if (selectedUnlinkedMeter.value) {
      return selectedUnlinkedMeter.value.commissioning
    }

    return undefined
  }
)
</script>

<template>
  <ModalContainer>
    <ModalTitle>Link Meters</ModalTitle>
    <ModalContent>
      <HeroSplashError v-if="meterId && !meterDetails" title="Meter not found." />

      <GridContainer v-else :columns="[164, '1fr']" :row-gap="10" :column-gap="10" align="center">
        <InputHidden name="id" :value="meterDetails?.id" />

        <GridItem><InputLabel>Status</InputLabel></GridItem>
        <GridItem>
          <InputSelect name="meterStatus"
                       :disabled="meterDetails?.meterStatus === MeterStatus.Decommissioned || meterDetails?.meterType === MeterType.Virtual"
                       error-variant="tooltip"
                       :items="[
                         { text: 'Operational', value: MeterStatus.Operational },
                         { text: 'Decommissioned', value: MeterStatus.Decommissioned },
                       ]"
                       :selected="meterDetails?.meterStatus"
                       @update:selected="selectedMeterStatus = $event"
                       item-text="text"
                       item-value="value" />
        </GridItem>

        <GridItem><InputLabel>Device ID</InputLabel></GridItem>
        <GridItem>
          <InputSelect name="deviceId"
                       error-variant="tooltip"
                       :placeholder="isFetchingUnlinkedMeters ? 'Loading...' : undefined"
                       :items="availableDeviceIds"
                       :disabled="meterDetails?.meterType === MeterType.Virtual"
                       :selected="meterDetails?.deviceId"
                       item-text="deviceId"
                       item-value="deviceId" />
        </GridItem>

        <template v-if="meterDetails?.meterType !== MeterType.Virtual">
          <GridItem><InputLabel>VCOM Device ID</InputLabel></GridItem>
          <GridItem>
            <InputSelect name="vcomDeviceId"
                         :items="availableVcomIds"
                         item-text="text"
                         item-value="value"
                         :placeholder="isFetchingUnlinkedMeters ? 'Loading...' : undefined"
                         error-variant="tooltip"
                         :selected="meterDetails?.vcomDeviceId ?? selectedUnlinkedMeter?.vcomDeviceId" />
          </GridItem>
        </template>

        <GridItem><InputLabel>Device Name</InputLabel></GridItem>
        <GridItem><InputText name="name" error-variant="tooltip" :value="meterDetails?.name" /></GridItem>

        <GridItem><InputLabel>Type</InputLabel></GridItem>
        <GridItem>
          <InputSelect name="meterType"
                       error-variant="tooltip"
                       :items="availableMeterTypes"
                       :disabled="meterDetails?.meterType === MeterType.Virtual"
                       :selected="meterDetails?.meterType"
                       @update:selected="selectedMeterType = $event"
                       item-text="text"
                       item-value="value" />
        </GridItem>

        <template v-if="meterDetails?.meterType !== MeterType.Virtual">
          <GridItem><InputLabel>Serial No.</InputLabel></GridItem>
          <GridItem><InputText name="serialNumber" error-variant="tooltip" :value="meterDetails?.serialNumber" /></GridItem>

          <GridItem><InputLabel>Vendor</InputLabel></GridItem>
          <GridItem><InputText name="vendor" error-variant="tooltip" :value="meterDetails?.vendor" /></GridItem>

          <GridItem><InputLabel>Model</InputLabel></GridItem>
          <GridItem><InputText name="model" error-variant="tooltip" :value="meterDetails?.model" /></GridItem>
        </template>

        <GridItem><InputLabel>Description</InputLabel></GridItem>
        <GridItem><InputText name="description" error-variant="tooltip" :value="meterDetails?.description" /></GridItem>

        <GridItem><InputLabel>Billing Register</InputLabel></GridItem>
        <GridItem>
          <InputSelect name="billingRegisterId"
                       error-variant="tooltip"
                       :items="meterBillingRegisters"
                       :selected="meterDetails?.billingRegisterId"
                       item-text="text"
                       item-value="value" />
        </GridItem>

        <template v-if="selectedMeterType !== MeterType.Virtual">
          <GridItem><InputLabel>Commissioning Date & Time</InputLabel></GridItem>
          <GridItem>
            <GridContainer :columns="['2fr', '1fr']">
              <GridItem><InputDate name="commissionedAtDate" error-variant="tooltip" :max="selectedMeterStatus === MeterStatus.Decommissioned ? currentValues.decommissionedAtDate : undefined" :value="commissioningDateValue" /></GridItem>
              <GridItem><InputTime name="commissionedAtTime" :value="commissioningDateValue ? format(commissioningDateValue!, DateFormats.HourMinute) : undefined" /></GridItem>
            </GridContainer>
          </GridItem>

          <GridItem><InputLabel>Take-On Reading</InputLabel></GridItem>
          <GridItem><InputNumber name="takeOnReading" error-variant="tooltip" :value="meterDetails?.takeOnReading" /></GridItem>
        </template>

        <template v-if="currentValues.meterStatus === MeterStatus.Decommissioned">
          <GridItem><InputLabel>Decommissioning Date & Time</InputLabel></GridItem>
          <GridItem>
            <GridContainer :columns="['2fr', '1fr']">
              <GridItem><InputDate name="decommissionedAtDate" error-variant="tooltip" :min="currentValues.commissionedAtDate" :value="meterDetails?.deCommissioning" /></GridItem>
              <GridItem><InputTime name="decommissionedAtTime" :value="meterDetails?.deCommissioning ? format(meterDetails.deCommissioning, DateFormats.HourMinute) : undefined" /></GridItem>
            </GridContainer>
          </GridItem>

          <GridItem><InputLabel>Close-Out Reading</InputLabel></GridItem>
          <GridItem><InputNumber name="closeOutReading" error-variant="tooltip" :value="meterDetails?.closeOutReading" /></GridItem>
        </template>
      </GridContainer>
    </ModalContent>

    <ModalFooter class="text-end">
      <ButtonSecondary @click="$emit('close')" :icon="faTimes" :disabled="isLinkingMeter">Close</ButtonSecondary>
      <ButtonPrimary class="ms-3" @click="doSubmit" :icon="faFloppyDisk" :preset="isLinkingMeter ? 'loading' : undefined">Save</ButtonPrimary>
    </ModalFooter>
  </ModalContainer>
</template>

<style scoped lang="scss">

</style>
