<script setup lang="ts">
import { useCustomerList } from '@/composables/assets/customers'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faFileExport,
  faHandPointUp,
  faPaperPlane,
  faSearch
} from '@fortawesome/pro-regular-svg-icons'
import { DateFormats, formatNumber } from "@/helpers/formatting";
import { format } from 'date-fns/format'
import { computed, ref, watch } from "vue";
import ModalCancelInvoice from '@/views/billing/invoicing/modal-confirm-cancel-invoice.vue'
import ModalConfirmSendInvoice from '@/views/billing/invoicing/modal-confirm-send-invoice.vue'
import {
  useApproveInvoice,
  usePaginatedInvoices,
  useRecreateInvoice, useRegenerateInvoice
} from "@/composables/billing/invoices";
import useLoader from '@/composables/loader'
import useNotifications from '@/composables/notifications'
import { z } from 'zod'
import { useDebounce } from "@vueuse/core";
import {
  usePageQueryParameter,
  usePageSizeQueryParameter,
  useQueryParameter,
  useSortingQueryParameter
} from '@/composables/query-parameters'
import ModalActivity from '@/views/billing/invoicing/modal-invoice-activity.vue'
import routeNames from '@/router/names'
import type { ComponentProps } from '@/types'
import { InvoiceStatus, invoiceStatusAsList, Urls } from "@/features/billing/constants";
import { PaginatedInvoiceSchema } from "@/features/billing/schemas";
import { CustomerSchema } from "@/features/assets/schemas";
import ButtonPrimary from "@/components/ButtonPrimary.vue";
import DataTablePagination from "@/components/data-table-pagination.vue";
import DropdownItem from "@/components/DropdownItem.vue";
import DropdownContext from "@/components/DropdownContext.vue";
import DataTable from "@/components/data-table.vue";
import DataTableCell from "@/components/data-table-cell.vue";
import BadgeCyan from "@/components/badge-cyan.vue";
import BadgeSecondary from "@/components/badge-secondary.vue";
import BadgePrimary from "@/components/badge-primary.vue";
import BadgeTeal from "@/components/badge-teal.vue";
import BadgeRed from "@/components/badge-red.vue";
import BadgeYellow from "@/components/badge-yellow.vue";
import DataTableHeader from "@/components/data-table-header.vue";
import HeroSplashError from "@/components/hero-splash-error.vue";
import ContentLoader from "@/components/content-loader.vue";
import InputText from "@/components/InputText.vue";
import InputDate from "@/components/InputDate.vue";
import InputSelect from "@/components/InputSelect.vue";
import { navigateTo } from "@/helpers/routing";
import { formatToMainCurrency } from '@/helpers/formatting'
import PageHeader from '@/components/page-header.vue'
import PageContent from '@/components/page-content.vue'
import ButtonHighlighted from '@/components/button-highlighted.vue'

defineOptions({ name: 'InvoiceDashboard' })

const { wrap } = useLoader()
const { addSuccess, addError } = useNotifications()

const { data: customerList, isLoading: isCustomerListLoading } = useCustomerList()

const page = usePageQueryParameter()
const pageSize = usePageSizeQueryParameter()
const sorting = useSortingQueryParameter()
const filter = useQueryParameter('filter', z.string().min(1))
const filterDebounced = useDebounce(filter, 500)

const customerIds = useQueryParameter('customers', z.array(CustomerSchema.shape.id), [])
const startDate = useQueryParameter('start', z.date({ coerce: true }).optional())
const endDate = useQueryParameter('end', z.date({ coerce: true }).optional())
const statuses = useQueryParameter('status', z.array(PaginatedInvoiceSchema.shape.status), [])
const selectedRecords = ref<number[]>([])

const {
  data,
  isFetching: invoiceListIsLoading,
  isError: invoiceListHasError
} = usePaginatedInvoices({
  page,
  pageSize,
  sorting,
  customerIds: useDebounce(customerIds, 500),
  startAt: startDate,
  endAt: endDate,
  filter: computed(() => filterDebounced.value),
  statuses: useDebounce(statuses, 500),
})

// Reset the page when filtering.
watch(filter, () => page.value = undefined)

// Reset the selected invoices when any filters change. Changing pages shouldn't
// trigger this.
watch(
  [startDate, endDate, filter, statuses, customerIds],
  (newValues, oldValues) => {
    // [statuses] and [customerIds] are always returning new arrays, so we need to
    // serialize the values to be able to compare them.
    const newValuesSerialized = JSON.stringify(newValues)
    const oldValuesSerialized = JSON.stringify(oldValues)

    if (newValuesSerialized !== oldValuesSerialized) {
      selectedRecords.value = []
    }
  })

const { mutateAsync: recreateInvoiceAsync } = useRecreateInvoice()

const { mutateAsync: approveInvoiceAsync } = useApproveInvoice()

const { mutateAsync: regenerateInvoiceAsync } = useRegenerateInvoice()

const recreateInvoice = (invoiceId: number) =>
  wrap(
    () => recreateInvoiceAsync(invoiceId)
  ).then(
    () => addSuccess({ title: 'Invoice recreated successfully' }),
    (response) => {

      var message = ''
      response.errors.map((error : { title: string }) => 
      {
        message = message + ' '+ error.title
      })
      addError({ title: 'Unable to recreate invoice.', message: message })
    }
  )

const approveInvoice = (invoiceId: number) =>
  wrap(
    () => approveInvoiceAsync(invoiceId)
  ).then(
    () => addSuccess({ title: 'Invoice approved' }),
    () => addError({ title: 'Unable to approve invoice', message: 'Please try again' })
  )

const regenerateInvoice = (invoiceId: number) =>
  wrap(
    () => regenerateInvoiceAsync(invoiceId)
  ).then(
    () => addSuccess({ title: 'Invoice regenerated successfully' }),
    () => addError({ title: 'Unable to regenerate invoice.', message: 'Please try again.' })
  )

const confirmCancellingInvoice = ref<ComponentProps<typeof ModalCancelInvoice>>()
const confirmSendingInvoice = ref<ComponentProps<typeof ModalConfirmSendInvoice>>()
const showingActivityFor = ref<ComponentProps<typeof ModalActivity>>()
const openNewWindow = window.open.bind(window)
</script>

<template>
  <PageHeader>
    <div>
      <div class="d-inline-block text-nowrap">
        <label class="me-2">Customer</label>
        <InputSelect
          searchable
          :placeholder="isCustomerListLoading ? 'Loading...' : undefined"
          :items="customerList || []"
          v-model:selected="customerIds"
          item-text="name"
          item-value="id"
          multiple
          :display-limit="0"
          inline />
      </div>

      <div class="d-inline-block text-nowrap ms-4">
        <label class="me-2">Status</label>
        <InputSelect
          name="status"
          :items="invoiceStatusAsList().filter(status => status.status !== InvoiceStatus.Paid)"
          multiple
          item-value="status"
          item-text="displayName"
          v-model:selected="statuses"
          :display-limit="0"
          inline />
      </div>

      <div class="d-inline-block text-nowrap ms-4">
        <label class="me-2">Start</label>
        <InputDate
          inline
          v-model:value="startDate"
          :max="endDate"
          :rules="
              endDate
                ? z.coerce.date().max(new Date(endDate)).optional()
                : z.coerce.date().optional()
            " />
      </div>

      <div class="d-inline-block text-nowrap ms-4">
        <label class="me-2">End</label>
        <InputDate
          inline
          v-model:value="endDate"
          :min="startDate"
          :rules="
              startDate
                ? z.coerce.date().min(new Date(startDate)).optional()
                : z.coerce.date().optional()
            " />
      </div>
    </div>
    <div>
      <InputText name="search" placeholder="Search" v-model:value="filter">
        <template #suffix><FontAwesomeIcon :icon="faSearch" /></template>
      </InputText>
    </div>
  </PageHeader>

  <PageContent>
    <ContentLoader :loading="invoiceListIsLoading" variant="table">
    <HeroSplashError v-if="invoiceListHasError" />

      <template v-else>
        <DataTable
          :records="data!.records"
          v-model:sorting="sorting"
          v-model:selection="selectedRecords"
          :selection-value="x => x.id">
          <template #headers>
            <DataTableHeader name="id">Invoice</DataTableHeader>
            <DataTableHeader name="customerId" :sort="false">Customer</DataTableHeader>
            <DataTableHeader name="projectCode">Project Id</DataTableHeader>
            <DataTableHeader name="projectName">Project Name</DataTableHeader>
            <DataTableHeader name="projectSize">Size (kWp)</DataTableHeader>
            <DataTableHeader name="usage">Usage (kWh)</DataTableHeader>
            <DataTableHeader name="amount">Invoice Amount (R)</DataTableHeader>
            <DataTableHeader name="savings">Period Savings (R)</DataTableHeader>
            <DataTableHeader name="date">Date</DataTableHeader>
            <DataTableHeader>Status</DataTableHeader>
            <DataTableHeader>Action</DataTableHeader>
            <DataTableHeader context><!-- Context --></DataTableHeader>
          </template>

          <template #record="{ record }">
            <DataTableCell>{{ record.id }}</DataTableCell>
            <DataTableCell>
              <template v-if="record.customerName">{{ record.customerName }}</template>
              <span v-else class="text-danger" v-tooltip.error="{ placement: 'bottom', text: `Missing data detected.<br>Please update the<br>information in Xero.` }">
                Update Needed
              </span>
            </DataTableCell>
            <DataTableCell>{{ record.projectCode }}</DataTableCell>
            <DataTableCell>{{ record.projectName }}</DataTableCell>
            <DataTableCell>{{ formatNumber(record.projectSize) }}</DataTableCell>
            <DataTableCell>{{ formatNumber(record.usage) }}</DataTableCell>
            <DataTableCell>{{ formatToMainCurrency(record.amount) }}</DataTableCell>
            <DataTableCell>{{ formatToMainCurrency(record.savings) }}</DataTableCell>
            <DataTableCell>{{
              format(record.invoiceDate, DateFormats.DayMonthYear)
            }}</DataTableCell>
            <DataTableCell>
              <BadgeYellow v-if="record.status === InvoiceStatus.Draft">Draft</BadgeYellow>
              <BadgeRed v-tooltip="record.actionRequired" v-else-if="record.status === InvoiceStatus.ActionRequired">Action Required</BadgeRed>
              <BadgeTeal v-else-if="record.status === InvoiceStatus.Paid">Paid</BadgeTeal>
              <BadgePrimary v-else-if="record.status === InvoiceStatus.Sent">Sent</BadgePrimary>
              <BadgeSecondary v-else-if="record.status === InvoiceStatus.Cancelled">Cancelled</BadgeSecondary>
              <BadgeCyan v-else-if="record.status === InvoiceStatus.Approved">Approved</BadgeCyan>
            </DataTableCell>
            <DataTableCell>
              <ButtonHighlighted
                v-if="record.status === InvoiceStatus.Draft"
                :icon="faHandPointUp"
                @click="approveInvoice(record.id)"
                >Approve</ButtonHighlighted>

              <ButtonHighlighted
                v-else-if="record.status === InvoiceStatus.ActionRequired"
                @click="navigateTo({ name: routeNames.invoice, params: { invoiceId: record.id } })"
                :icon="faHandPointUp"
                >Update
              </ButtonHighlighted>

              <ButtonHighlighted
                v-else-if="record.status === InvoiceStatus.Approved"
                @click="
                  confirmSendingInvoice = {
                    invoiceId: record.id,
                    projectCode: record.projectCode,
                    projectName: record.projectName
                  }
                "
                :icon="faPaperPlane"
                >Send
              </ButtonHighlighted>
            </DataTableCell>

            <DataTableCell>
              <DropdownContext>
                <DropdownItem
                  v-if="record.status === InvoiceStatus.Cancelled"
                  @click="recreateInvoice(record.id)"
                  >Recreate</DropdownItem
                >
                <DropdownItem
                  v-if="[InvoiceStatus.Approved, InvoiceStatus.Sent].indexOf(record.status) > -1"
                  @click="regenerateInvoice(record.id)"
                  >Regenerate PDF</DropdownItem
                >
                <DropdownItem
                  v-if="[InvoiceStatus.ActionRequired, InvoiceStatus.Draft].indexOf(record.status) > -1"
                  @click="navigateTo({ name: routeNames.invoice, params: { invoiceId: record.id } })"
                  >Edit</DropdownItem
                >
                <DropdownItem
                  v-if="[InvoiceStatus.Draft, InvoiceStatus.Approved, InvoiceStatus.Sent, InvoiceStatus.Paid].indexOf(record.status) > -1"
                  @click="openNewWindow(record.previewUrl)"
                  >Preview</DropdownItem
                >
                <DropdownItem
                  v-if="[InvoiceStatus.Approved, InvoiceStatus.Cancelled, InvoiceStatus.Sent, InvoiceStatus.Paid].indexOf(record.status) > -1"
                  @click="openNewWindow(record.downloadUrl)"
                  >Download</DropdownItem
                >
                <DropdownItem
                  v-if="[InvoiceStatus.ActionRequired, InvoiceStatus.Draft, InvoiceStatus.Approved, InvoiceStatus.Sent].indexOf(record.status) > -1"
                  @click="
                    confirmCancellingInvoice = {
                      status: record.status,
                      invoiceId: record.id,
                      projectCode: record.projectCode,
                      projectName: record.projectName,
                    }
                  "
                  >Cancel</DropdownItem
                >
                <DropdownItem
                  v-if="[InvoiceStatus.ActionRequired, InvoiceStatus.Draft, InvoiceStatus.Approved, InvoiceStatus.Sent].indexOf(record.status) > -1"
                  @click="navigateTo({ name: routeNames.assetSettings, params: { assetCode: record.assetCode } })"
                  >Settings</DropdownItem
                >
                <DropdownItem
                  @click="
                    showingActivityFor = { invoiceId: record.id, title: `${record.projectName} (${record.projectCode})` }
                  "
                  >Activity</DropdownItem
                >
              </DropdownContext>
            </DataTableCell>
          </template>
        </DataTable>

        <DataTablePagination
          :total-records="data!.paging.totalRecords"
          :page-size="data!.paging.pageSize"
          @update:page-size="pageSize = $event"
          :page="data!.paging.page"
          @update:page="page = $event">
          <template v-if="selectedRecords.length > 0">
            <form :action="Urls.exportInvoices" method="post">
              <input v-for="v in selectedRecords" :key="v" type="hidden" name="invoiceIds[]" :value="v" />
              <ButtonPrimary type="submit" :icon="faFileExport">Export List</ButtonPrimary>
            </form>
          </template>
        </DataTablePagination>
      </template>
    </ContentLoader>

    <ModalActivity
      v-if="showingActivityFor"
      v-bind="showingActivityFor"
      @close="showingActivityFor = undefined" />
    <ModalCancelInvoice
      v-if="confirmCancellingInvoice"
      v-bind="confirmCancellingInvoice"
      @close="confirmCancellingInvoice = undefined" />
    <ModalConfirmSendInvoice
      v-if="confirmSendingInvoice"
      v-bind="confirmSendingInvoice"
      @close="confirmSendingInvoice = undefined" />
  </PageContent>
</template>

<style scoped lang="scss"></style>
