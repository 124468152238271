import { z } from 'zod'
import { ExportPowerEscalationType, InvoiceStatus, TariffStructureType } from "@/features/billing/constants";
import { mainCurrency } from '@/helpers/validation'

export type PaginatedInvoice = z.infer<typeof PaginatedInvoiceSchema>
export type InvoiceActivity = z.infer<typeof InvoiceActivitySchema>
export type InvoiceMeterReadings = z.infer<typeof InvoiceMeterReadingsSchema>

export const AssetTariffSchema = z.object({
  tariffStructureId: z.nativeEnum(TariffStructureType),
  exportPower: z.boolean(),
  exportPowerEscalationTypeId: z.nativeEnum(ExportPowerEscalationType).nullish(),
  exportPowerEscValue: z.number().gte(0).nullish(),
  exportPowerEscDate: z.coerce.date().nullish(),
  billingRates: z.array(
    z.object({
      id: z.number().int().gt(0),
      tariffTypeId: z.number().int().gt(0).nullish(),
      rateInCents: mainCurrency(z.number().gt(0)),
      exportPowerInCents: mainCurrency(z.number()).nullish(),
    })
  )
})

export const PaginatedInvoiceSchema = z.object({
  id: z.number().int().gt(0),
  customerId: z.string().nullable(),
  customerName: z.string().nullable(),
  assetCode: z.number().int().gt(0),
  projectCode: z.number().int().gt(0),
  projectName: z.string(),
  projectSize: z.number(),
  usage: z.number(),
  amount: z.number(),
  savings: z.number(),
  invoiceDate: z.date({ coerce: true }),
  status: z.nativeEnum(InvoiceStatus),
  actionRequired: z.string().nullish(),
})

export const InvoiceSchema = z.object({
    id: z.number().int().gt(0),
    billingConfigurationId: z.number().int().gt(0),
    assetCode: z.number().gte(0),
    invoiceStatusId: z.nativeEnum(InvoiceStatus),
    invoiceNumber: z.string().nullable(),
    invoiceExternalReferenceNumber: z.string().nullable(),
    accountReferenceNumber: z.string().nullable(),
    poNumber: z.string().nullable(),
    invoiceDate: z.coerce.date(),
    periodStartDate: z.coerce.date(),
    periodEndDate: z.coerce.date(),
    gridTariff: z.number().gte(0),
    totalEnergyConsumption: z.number(),
    totalGridConsumption: z.number(),
    totalEnergyExported: z.number().nullable(),
    totalAmountExcludingTax: z.number(),
    tax: z.number(),
    totalAmountIncludingTax: z.number(),
    totalSavingsToDate: z.coerce.number(),
    showMeterReadings: z.boolean(),
    showSavings: z.boolean(),
    dueDate: z.coerce.date(),
    isExportPower: z.boolean(),
    trackingCategoryReference: z.string(),
    customer: z.object({
      customerExternalIdentifier: z.string(),
      name: z.string(),
      addressLineOne: z.string().nullish(),
      addressLineTwo: z.string().nullish(),
      suburb: z.string().nullish(),
      city: z.string().nullish(),
      region: z.string().nullish(),
      country: z.string().nullish(),
      vatNumber: z.string().nullish()
    }).nullable(),
    contractFundEntity: z.object({
      id: z.number().int().gt(0),
      name: z.string(),
      registrationNumber: z.string(),
      address: z.string(),
      vatNumber: z.string(),
      xeroInvoiceAccountNumber: z.string(),
      contactEmailAddress: z.string(),
      contactTelephoneNumber: z.string(),
      bank: z.string(),
      accountName: z.string(),
      accountNumber: z.string(),
      branchCode: z.string()
    }),
    invoiceSavings: z.object({
      id: z.number().int().gt(0),
      invoiceId: z.number().int().gt(0),
      amount: z.number()
    }).nullable(),
    invoiceDocument: z.object({
      id: z.number().int().gt(0),
      invoiceId: z.number().int().gt(0),
      documentId: z.number().gte(0),
      createDateTime: z.coerce.date(),
      updateDateTime: z.coerce.date(),
      document: z.object({
        id: z.number().int().gt(0),
        fileFormat: z.string(),
        documentType: z.string(),
        contentAsBase64String: z.string(),
        createDateTime: z.coerce.date(),
        updateDateTime: z.coerce.date()
      }).nullable()
    }).nullable(),
    invoiceCharges: z.array(
      z.object({
        id: z.number().int().gt(0),
        invoiceId: z.number().int().gt(0),
        chargeType: z.object({
          id: z.number().int().gt(0),
          name: z.string()
        }),
        tariffTypeId: z.number().int().gt(0),
        externalReferenceNumber: z.string().nullish(),
        description: z.string(),
        quantity: z.number(),
        rate: z.number()
      })),
    meterReadings: z.array(z.object({
      id: z.number().int().gt(0),
      invoiceId: z.number().int().gt(0),
      deviceId: z.string(),
      meterType: z.object({
      id: z.number().int().gt(0),
      name: z.string()
      }),
      serialNumber: z.string().nullish(),
      forwardActiveStartReading: z.number(),
      forwardActiveEndReading: z.number(),
      reverseActiveStartReading: z.number(),
      reverseActiveEndReading: z.number(),
      hide: z.boolean()
    }))
})

export const InvoiceConfigSchema = z.object({
  id: z.number().int().gte(0),
  billingConfigurationId: z.number().int().gt(0),
  assetCode: z.number().gte(0),
  invoiceStatusId: z.nativeEnum(InvoiceStatus),
  invoiceNumber: z.string().nullable(),
  invoiceExternalReferenceNumber: z.string().nullable(),
  accountReferenceNumber: z.string().nullable(),
  poNumber: z.string().nullable(),
  invoiceDate: z.coerce.date(),
  periodStartDate: z.coerce.date(),
  periodEndDate: z.coerce.date(),
  gridTariff: z.number().gte(0),
  totalEnergyConsumption: z.number(),
  totalGridConsumption: z.number(),
  totalEnergyExported: z.number().nullable(),
  totalAmountExcludingTax: z.number(),
  tax: z.number(),
  totalAmountIncludingTax: z.number(),
  totalSavingsToDate: z.coerce.number(),
  showMeterReadings: z.boolean(),
  showSavings: z.boolean(),
  dueDate: z.coerce.date(),
  isExportPower: z.boolean(),
  trackingCategoryReference: z.string().nullish(),
  customer: z.object({
    customerExternalIdentifier: z.string(),
    name: z.string(),
    addressLineOne: z.string().nullish(),
    addressLineTwo: z.string().nullish(),
    suburb: z.string().nullish(),
    city: z.string().nullish(),
    region: z.string().nullish(),
    country: z.string().nullish(),
    vatNumber: z.string().nullish()
  }).nullable(),
  contractFundEntity: z.object({
    id: z.number().int().gt(0),
    name: z.string(),
    registrationNumber: z.string(),
    address: z.string(),
    vatNumber: z.string(),
    xeroInvoiceAccountNumber: z.string(),
    contactEmailAddress: z.string(),
    contactTelephoneNumber: z.string(),
    bank: z.string(),
    accountName: z.string(),
    accountNumber: z.coerce.number().transform(String),
    branchCode: z.string()
  }),
  invoiceSavings: z.object({
    id: z.number().int().gt(0),
    invoiceId: z.number().int().gt(0),
    amount: z.number()
  }).nullable(),
  invoiceDocument: z.object({
    id: z.number().int().gt(0),
    invoiceId: z.number().int().gt(0),
    documentId: z.number().gte(0),
    createDateTime: z.coerce.date(),
    updateDateTime: z.coerce.date(),
    document: z.object({
      id: z.number().int().gt(0),
      fileFormat: z.string(),
      documentType: z.string(),
      contentAsBase64String: z.string(),
      createDateTime: z.coerce.date(),
      updateDateTime: z.coerce.date()
    }).nullable()
  }).nullable(),
  invoiceCharges: z.array(
    z.object({
      id: z.number().int(),
      invoiceId: z.number().int(),
      chargeType: z.object({
        id: z.number().int().gt(0),
        name: z.string()
      }),
      tariffTypeId: z.number().int(),
      externalReferenceNumber: z.string().nullish(),
      description: z.string(),
      quantity: z.number(),
      rate: z.number()
    })),
  meterReadings: z.array(z.object({
    id: z.number().int(),
    invoiceId: z.number().int(),
    deviceId: z.string(),
    meterType: z.object({
    id: z.number().int().gt(0),
    name: z.string()
    }),
    serialNumber: z.string().nullish(),
    forwardActiveStartReading: z.number(),
    forwardActiveEndReading: z.number(),
    reverseActiveStartReading: z.number(),
    reverseActiveEndReading: z.number(),
    hide: z.boolean()
  })).optional()
})

export const InvoiceDetailSchema = z.object({
    id: z.number().int().gt(0),
    billingConfigurationId: z.number().int().gt(0),
    assetCode: z.number().gte(0),
    invoiceStatusId: z.nativeEnum(InvoiceStatus),
    invoiceNumber: z.string().nullable(),
    invoiceExternalReferenceNumber: z.string().nullable(),
    accountReferenceNumber: z.string().nullable(),
    poNumber: z.string().nullable(),
    invoiceDate: z.coerce.date(),
    periodStartDate: z.coerce.date(),
    periodEndDate: z.coerce.date(),
    gridTariff: z.number().gte(0),
    totalEnergyConsumption: z.number(),
    totalGridConsumption: z.number(),
    totalAmountExcludingTax: z.number(),
    tax: z.number(),
    totalAmountIncludingTax: z.number(),
    totalSavingsToDate: z.coerce.number(),
    showMeterReadings: z.boolean(),
    showSavings: z.boolean(),
    dueDate: z.coerce.date()
})

export const InvoiceChargeSchema = z.object({
  id: z.number().int().gt(0),
  invoiceId: z.number().int().gt(0),
  chargeType: z.object({
    id: z.number().int().gt(0),
    name: z.string()
  }),
  tariffTypeId: z.number().int().gt(0),
  externalReferenceNumber: z.string(),
  description: z.string(),
  quantity: z.number(),
  rate: z.number(),
  total: z.number()
})

export const InvoiceMeterReadingsSchema = z.object({
  id: z.number().int().gt(0),
  invoiceId: z.number().int().gt(0),
  deviceId: z.string(),
  meterType: z.object({
    id: z.number().int().gt(0),
    name: z.string()
  }),
  serialNumber: z.string().nullable(),
  forwardActiveStartReading: z.number(),
  forwardActiveEndReading: z.number(),
  reverseActiveStartReading: z.number(),
  reverseActiveEndReading: z.number(),
  totalConsumption: z.number().nullable().optional(),
  totalExport: z.number().nullable().optional()
})

export const InvoiceActivitySchema = z.object({
  activity: z.string(),
  userId: z.string().optional(),
  date: z.date({ coerce: true })
})

export const TariffSchema = z.object({
  tariffStructures: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      tariffTypes: z.array(
        z.object({
          id: z.number(),
          name: z.string()
        })
      )
    })
  )
})
